import React, { useState } from 'react';
import { Button, Typography, AppBar, IconButton, Toolbar, CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import SmartSiteDrawer from 'components/SmartSiteDrawer';
import Menu from './Menu';

const drawerWidth = 440;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px) !important`,
    marginLeft: `${drawerWidth}px !important`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: '92px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const SmartSiteScaffold = ({ children, defaultHidden, defaultSelection, totalSavingsYTD, totalSavingsWeekly, totalSavingsMonthly, totalSavingsDaily }) => {
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(!defaultHidden);
  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          {drawerOpen ? null : (<IconButton
            color='inherit'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, drawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>)}
          <Typography variant='h5' component='div'>SmartSite</Typography>
          <Menu defaultSelection={defaultSelection} />
          <Typography component='div' sx={{flexGrow: 1}}>&nbsp;</Typography>
          <Button color='inherit' onClick={() => history.replace('/')}>Logout</Button>
        </Toolbar>
      </AppBar>
      <SmartSiteDrawer open={drawerOpen} handleDrawerClose={handleDrawerClose} totalSavingsWeekly={totalSavingsWeekly} totalSavingsDaily={totalSavingsDaily} totalSavingsMonthly={totalSavingsMonthly} totalSavingsYTD={totalSavingsYTD}/>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        {children}
      </main>
    </div>
  );
};

export default SmartSiteScaffold;
