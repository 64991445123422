import React, {useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import BuildingCard from 'components/BuildingCard';
import { loadBuilding } from 'store/Building/building.actions';
import SmartSiteScaffold from 'components/SmartSiteScaffold';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
}));

const mockBuildings = [
  {
    name: '141 Erskine Ave',
    status: 'heat',
    health: 'ok',
    alerts: 0,
    savings: '$0K',
    lastUpdated: '2 minutes ago',
  },
  {
    name: '500 Duplex Ave',
    status: 'heat',
    health: 'ok',
    alerts: 0,
    savings: '$0K',
    lastUpdated: '1 hour ago',
    disabled: true,
  },
  {
    name: '20 Tuxedo Ct',
    status: 'heat',
    health: 'ok',
    alerts: 0,
    savings: '$0K',
    lastUpdated: 'a moment ago',
    disabled: true
  },
];

const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.building.loading);
  const currentBuilding = useSelector((store) => store.building.currentBuilding);
  const [mBuildings, setMBuildings] = useState(mockBuildings);
  const updateCurrentBuilding = () => {
    console.log('tock');
    const newMBuildings = [...mBuildings];
    newMBuildings[0]['alerts'] = currentBuilding.alertCount;
    newMBuildings[0]['savings'] = `$${Math.round(currentBuilding.totalSavingsYTD / 1000)}K`;
    newMBuildings[0]['health'] = currentBuilding.alertCount > 0 ? 'warn' : 'ok';
    newMBuildings[0]['status'] = currentBuilding.isWinter ? 'heat': 'ac';
    newMBuildings[0]['lastUpdated'] = currentBuilding.lastUpdated.fromNow();
    const timeDIF = moment().subtract(currentBuilding.lastUpdated);
    if (timeDIF.minute() >= 5) newMBuildings[0]['overtime'] = true;
    setMBuildings(newMBuildings);
  }
  useEffect(() => {
    const id = setInterval(() => {
      if (currentBuilding) updateCurrentBuilding();
    }, 5000);
    if (currentBuilding) updateCurrentBuilding();
    return () => clearInterval(id)
  }, [currentBuilding]);
  useEffect(() => {
    dispatch(loadBuilding(null));
  }, [dispatch]);
  return ((loading || !currentBuilding) ? (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
    ) : (
      <SmartSiteScaffold 
        defaultSelection={0} 
        totalSavingsYTD={currentBuilding.totalSavingsYTDF}
        totalSavingsMonthly={currentBuilding.totalSavingsMonthlyF}
        totalSavingsWeekly={currentBuilding.totalSavingsWeeklyF}
        totalSavingsDaily={currentBuilding.totalSavingsDailyF}
      >
        <div className={classes.grid}>
          {mBuildings.map((building) => (
            <BuildingCard key={building.name} {...building} />
          ))}
        </div>
      </SmartSiteScaffold>
    )
  );
};

export default HomePage;
