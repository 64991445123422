import React, {useEffect} from 'react';
import Chart from 'chart.js/auto';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import moment from 'moment';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  coolingTemp: {
    flex: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Wallpoet", cursive',
    fontSize: '1.8rem',
    backgroundColor: 'lightgrey',
    whiteSpace: 'nowrap',
    paddingLeft: 6,
    paddingRight: 6,
    marginRight: 14,
    lineHeight: '32px'
  },
  heatingTemp: {
    flex: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Wallpoet", cursive',
    fontSize: '1.8rem',
    backgroundColor: 'lightgrey',
    whiteSpace: 'nowrap',
    paddingLeft: 6,
    paddingRight: 6,
    marginRight: 14,
    lineHeight: '32px'
  },
  list: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 16
  },
  col: {
    flex: 1
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '0.8rem !important'
  },
  on: {
    marginBottom: '-6px !important',
    marginLeft: '4px !important',
    marginRight: '4px !important'
  },
  off: {
    marginBottom: '-6px !important',
    marginLeft: '4px !important',
    marginRight: '4px !important'
  }
}));

const drawHeating = (heatingPoints) => {
  const heatingCtx = document.getElementById('heatingCanvas').getContext('2d');
  const heatingChart = new Chart(heatingCtx, {
    type: 'line',
    data: {
      datasets: [
        {
          label: 'DHW supply temperature',
          tension: 0.4,
          borderColor: 'grey',
          data: heatingPoints,
          borderColor: "#304659",
          pointRadius: 0,
        },
      ],
    },
    options: {
      responsive: true,
      parsing: {
        xAxisKey: 'time',
        yAxisKey: 'value',
      },
      scales: {
        x: {
          ticks: {
            color: 'grey',
          },
        },
      },
    },
  });
  return heatingChart;
};
const drawCooling = (coolingPoints1, coolingPoints2) => {
  const coolingCtx = document.getElementById('coolingCanvas').getContext('2d');
  coolingCtx['$chartjs'] = undefined;
  const coolingChart = new Chart(coolingCtx, {
    type: 'line',
    data: {
      datasets: [
        {
          label: 'North supply temperature',
          tension: 0.4,
          borderColor: 'grey',
          data: coolingPoints1,
          borderColor: "#304659",
          pointRadius: 0,
        },
        {
          label: 'South supply temperature',
          tension: 0.4,
          borderColor: 'grey',
          data: coolingPoints2,
          borderColor: "#D66264",
          pointRadius: 0,
        },
      ],
    },
    options: {
      responsive: true,
      parsing: {
        xAxisKey: 'time',
        yAxisKey: 'value',
      },
      scales: {
        x: {
          ticks: {
            color: 'grey',
          },
        },
      },
    },
  });
  return coolingChart;
};




const HeatingCoolingInfo = ({ heatingMachineValues, coolingMachineValues1, coolingMachineValues2, heatingMachineStatuses, coolingMachineStatuses, heatingValue, coolingValue }) => {
  const classes = useStyles();
  useEffect(() => {
    const chartHeating = drawHeating(heatingMachineValues);
    const chartCooling = drawCooling(coolingMachineValues1, coolingMachineValues2);
    return () => {
      chartHeating.destroy();
      chartCooling.destroy();
    }
  }, [heatingMachineValues, coolingMachineValues1, coolingMachineValues2]);

  const drawStatusItem = (label, status) => {
    return (
      <div className={classes.item}>
        <span>{label}</span>
        <span>
          {status ? (
            <span>
              ON<CircleIcon className={classes.on} />
            </span>
          ) : (
            <span>
                OFF<CircleOutlinedIcon className={classes.off} />
            </span>
          )}
        </span>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.col}>
        <div className={classes.header}>
          <div className={classes.coolingTemp}>{coolingValue[0]}° C <br/>{coolingValue[1]}° C</div>
          <div className={classes.list}>
            {coolingMachineStatuses.map(({label, status}) => drawStatusItem(label, status))}
          </div>
        </div>
        <canvas id='coolingCanvas' />
      </div>
      <div className={classes.col}>
        <div className={classes.header}>
          <div className={classes.heatingTemp}>{heatingValue}° C</div>
          <div className={classes.list} style={{marginBottom: 22}}>
            {heatingMachineStatuses.map(({label, status}) => drawStatusItem(label, status))}
          </div>
        </div>
        <canvas id='heatingCanvas' />
      </div>
    </div>
  );
};

export default HeatingCoolingInfo;
