import React from 'react';
import { Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalInvestments: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionDivider: {
    marginTop: `${theme.spacing(3)} !important`,
    marginBottom: `${theme.spacing(3)} !important`,
  },
  title: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  bigValue: {
    marginBottom: theme.spacing(2),
  },
}));

const Totals = ({totalSavingsYTD, totalSavingsMonthly, totalSavingsWeekly, totalSavingsDaily}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/*<Typography variant='h5' className={classes.title}>
        Total investments
      </Typography>
      <div className={classes.totalInvestments}>
        <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            Outstanding
          </Typography>
          <Typography variant='h4' className={classes.smallValue}>
            25
          </Typography>
        </div>
        <Divider orientation='vertical' flexItem />
        <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            In progress
          </Typography>
          <Typography variant='h4' className={classes.smallValue}>
            57
          </Typography>
  </div>
        <Divider orientation='vertical' flexItem />
        <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            Completed
          </Typography>
          <Typography variant='h4' className={classes.smallValue}>
            136
          </Typography>
        </div>
  </div> */}
      <Divider className={classes.sectionDivider} />
      <Typography variant='h5' className={classes.title}>
        Total savings this year
      </Typography>
      <Typography variant='h2' className={classes.bigValue}>
        {totalSavingsYTD}
      </Typography>
      <Divider className={classes.sectionDivider}/>
      <div className={classes.totalInvestments}>
        <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            Today
          </Typography>
          <Typography variant='h5' className={classes.smallValue}>
            {totalSavingsDaily}
          </Typography>
        </div>
        <Divider orientation='vertical' flexItem />
        <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            This week
          </Typography>
          <Typography variant='h5' className={classes.smallValue}>
            {totalSavingsWeekly}
          </Typography>
        </div>
        <Divider orientation='vertical' flexItem />
        <div className={classes.item}>
          <Typography variant='subtitle1' className={classes.subtitle}>
            This month
          </Typography>
          <Typography variant='h5' className={classes.smallValue}>
            {totalSavingsMonthly}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Totals;
