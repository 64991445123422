import React, { useState } from 'react';
import { Typography, Card, CardContent, Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import logo from './logo.png';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  top: {
    backgroundColor: theme.palette.primary.main,
    width: 'calc(100% - 320px)',
    height: '40vh',
    padding: '50px 160px',
  },
  bottom: {
    height: '50vh'
  },
  logo: {
    width: '124px',
  },
  tagline: {
    color: 'white',
    marginTop: '40px',
  },
  loginCard: {
    padding: '24px',
    marginLeft: '32px',
  },
  input: {
    width: '100%',
    marginBottom: '8px !important',
    marginTop: '16px !important',
  },
  button: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const login = () => {
    if ((username === 'admin' && password === 'dimax') || (username === 'admin' && password === 'qres')) {
      history.push('/home');
    } else {
      alert('Invalid credentials!')
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <img alt='logo' src={logo} className={classes.logo} />
        <Typography variant='h3' className={classes.tagline}>
          SMARTSITE <br />
          THE SMARTER WAY TO <br />
          MANAGE YOUR FACILITY
        </Typography>
      </div>
      <div className={'bottom-login'}>
        <div className={classes.left}>
          <Typography variant={'h4'} className={classes.subtitle}>
            Visibility in real-time means a better managed operation.
          </Typography>
          <Typography variant={'body1'} className={classes.more_info}>
          </Typography>
        </div>
        <div className={classes.right}>
          <Card className={classes.loginCard}>
            <CardContent>
              <Typography variant={'h6'}>SIGN IN</Typography>
              <TextField
                className={classes.input}
                variant={'filled'}
                placeholder='Name'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                className={classes.input}
                variant={'filled'}
                placeholder='Password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                size={'large'}
                className={classes.button}
                variant={'contained'}
                color='primary'
                onClick={login}
              >
                LOGIN
              </Button>
              <Typography variant='caption'>Not yet a customer of Kontrol SmartSite? <a href='mailto:support@kontrolenergy.com'>Contact us</a></Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
