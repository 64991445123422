export const getPoints = async (id, point, currentValue) => {
    try {
        const url = 'https://api.smartsiteportal.com'
        const apiKey = 'wFjRggzLCgtKQUFcWRdKlxC8BOarNxFw';
        const headers = {
            'API-Token': apiKey,
        };
        const endpoint = `${url}/v1/point/${id}${currentValue ? '/CurrentValue' : ''}`;
        const result = await fetch(endpoint, {
            headers,
            method: 'GET'
        });
        console.log(result)
        return {point, value: await result.json()};
    } catch (e) { 
        console.error(e)
        return null;
    }
}