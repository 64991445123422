import buildingTypes from './building.types';
import { getPoints } from 'api';
import moment from 'moment';

export const getAllBuildingsSuccess = (clients) => ({
  type: buildingTypes.GET_ALL_BUILDINGS_SUCCESS,
  payload: clients,
});

export const getAllBuildingsFailure = (error) => ({
  type: buildingTypes.GET_ALL_BUILDINGS_FAILURE,
  payload: error,
});

export const loadBuilding = (id) => async (dispatch) => {
  const data = {};

  // CONTACTS - MOCK
  data.contacts = {
    name: '141 Erskine Ave',
    openIssues: 'NA',
    inProgressIssues: 'NA',
    lifetimeSavings: 'NA',
    annualSavings: 'NA',
    firstContactTitle: 'Operator',
    firstContactName: 'Kyle Mitford',
    firstContactInfo: '416-570-8210',
    secondContactTitle: 'HVAC Contractor',
    secondContactName: 'New Found Air',
    secondContactInfo: 'service@newfoundair.ca',
    siteId: 16802
  };
  const points = [
    {id: '1021280', point: 'Boiler 1 Alarm', currentValue: true},
    {id: '1021289', point: 'Boiler 1 Status', currentValue: true},
    {id: '1021299', point: 'Boiler 2 Alarm', currentValue: true},
    {id: '1021308', point: 'Boiler 2 Status', currentValue: true},
    {id: '1021317', point: 'Boiler 3 Alarm', currentValue: true},
    {id: '1021324', point: 'Boiler 3 Status', currentValue: true},
    {id: '1021311', point: 'DHW Boiler 1 Alarm', currentValue: true},
    {id: '1021573', point: 'DHW Boiler 1 Status', currentValue: true},
    {id: '1021327', point: 'DHW Boiler 2 Alarm', currentValue: true},
    {id: '1021583', point: 'DHW Boiler 2 Status', currentValue: true},
    {id: '1021288', point: 'DHW Building Supply Temp', currentValue: true},
    {id: '1021288', point: 'DHW Building Supply Temp Series', currentValue: false},
    {id: '1022174', point: 'North Heating Pump Alarm', currentValue: true},
    {id: '1021300', point: 'North Htg Supply Temp', currentValue: true},
    {id: '1021300', point: 'North Htg Supply Temp Series', currentValue: false},
    {id: '1022171', point: 'South Heating Pump Alarm', currentValue: true},
    {id: '1021281', point: 'South Htg Supply Temp', currentValue: true},
    {id: '1021281', point: 'South Htg Supply Temp Series', currentValue: false},
    {id: '1021275', point: 'Summer/Winter Switch', currentValue: true}
  ];
  const pointValues = await Promise.all(points.map(({id, point, currentValue}) => getPoints(id, point, currentValue)));
  const pointMap = {}
  pointValues.forEach(({point, value}) => pointMap[point] = value);
  data.points = pointMap;

  // savings
  const gasValues = [
    ['12',	7176,],
    ['01',	15787,],
    ['02',	16691,],
    ['03',	11985,],
    ['04',	9263,],
    ['05',	8386,],
    ['06',	2089,],
    ['07',	1980,],
    ['08',	2462,],
    ['09', 1903],
    ['10', 4358],
    ['11', 7953]
  ];
  const oldGasValues = [
    ['12',	11343,],
    ['01',	13395,],
    ['02',	13345,],
    ['03',	10919,],
    ['04',	9525,],
    ['05',	9150,],
    ['06',	1966,],
    ['07',	0,],
    ['08',	0,],
    ['09', 0],
    ['10', 0],
    ['11', 0]
  ]
  const waterValues = [
    ['11',	0],
    ['12',	7619],
    ['01',	7702],
    ['02',	7380],
    ['03',	8176],
    ['04',	7677],
    ['05',	7586],
    ['06',	10495],
    ['07',	11415],
    ['08',	10542],
    ['09', 11309],
    ['10', 8318]
  ];
  const oldWaterValues = [
    ['11',	7248],
    ['12',	6888],
    ['01',	7094],
    ['02',	7204],
    ['03',	7056],
    ['04',	7857],
    ['05',	7735],
    ['06',	7776],
    ['07',	0],
    ['08',	0],
    ['09', 0],
    ['10', 0]
  ]

  const elecValues = [
    ['11',	0],
    ['12',	3437],
    ['01',	4366],
    ['02',	5012],
    ['03',	2879],
    ['04',	2798],
    ['05',	3325],
    ['06',	3895],
    ['07',	3014],
    ['08',	3203],
    ['09', 2781],
    ['10', 3094]
  ];
  const oldElecValues = [
    ['11',	4068],
    ['12',	2938],
    ['01',	4825],
    ['02',	5152],
    ['03',	4760],
    ['04',	4970],
    ['05',	5096],
    ['06',	1063],
    ['07',	0],
    ['08',	0],
    ['09', 0],
    ['10', 0]
  ]
  data.gasValues = gasValues;
  data.oldGasValues = oldGasValues;
  data.elecValues = elecValues;
  data.oldElecValues = oldElecValues;
  data.waterValues = waterValues;
  data.oldWaterValues = oldWaterValues;

  // savings
  data.gasSavingsYTD = -1734;
  data.elecSavingsYTD = 5890;
  data.waterSavingsYTD = -5025;
  data.totalSavingsYTD = -869
  data.totalSavingsYTDF = '-$869';
  data.totalSavingsMonthlyF = '-$72';
  data.totalSavingsWeeklyF = '-$18';
  data.totalSavingsDailyF = '-$2'

  // health
  const formatNum = (value) => Math.round(parseFloat(value) * 10) / 10;
  data.health = [
    {label: 'Heating Plant', items: [
      {label: 'Boiler 1', type: 'alert', alert: pointMap['Boiler 1 Alarm'].value !== 'Normal'},
      {label: 'Boiler 2', type: 'alert', alert: pointMap['Boiler 2 Alarm'].value !== 'Normal'},
      {label: 'Boiler 3', type: 'alert', alert: pointMap['Boiler 3 Alarm'].value !== 'Normal'},
      {label: 'North Zone Pump', type: 'alert', alert: pointMap['North Heating Pump Alarm'].value !== 'Normal'},
      {label: 'South Zone Pump', type: 'alert', alert: pointMap['South Heating Pump Alarm'].value !== 'Normal'},
      {label: 'North Zone Supply Temp', type: 'value', value: formatNum(pointMap['North Htg Supply Temp'].value)},
      {label: 'South Zone Supply Temp', type: 'value', value: formatNum(pointMap['South Htg Supply Temp'].value)}

    ]},
    {label: 'Domestic Hot Water', items: [
      {label: 'DHW Boiler 1', type: 'alert', alert: pointMap['DHW Boiler 1 Alarm'].value !== 'Normal'},
      {label: 'DHW Boiler 2', type: 'alert', alert: pointMap['DHW Boiler 2 Alarm'].value !== 'Normal'},
      {label: 'DHW Supply Temp', type: 'value', value: formatNum(pointMap['DHW Building Supply Temp'].value)}
    ]},
  ]
  data.health = data.health.map(category => ({
    ...category,
    alert: category.items.some(item => item.alert)
  }));
  data.alertCount = 0;
  data.health.forEach(category => category.items.forEach(item => item.alert && (data.alertCount = data.alertCount + 1)))

  // heating
  const convertTimestamp = ({year, monthValue, dayOfMonth, hour, minute}) => (
    moment({year, month: parseInt(monthValue) - 1, day: dayOfMonth, hour, minute}).format('HH:mm')
  );
  data.coolingMachineValues1 = pointMap['North Htg Supply Temp Series'].map(point => ({...point, time: convertTimestamp(point.timestamp)}));
  data.coolingMachineValues2 = pointMap['South Htg Supply Temp Series'].map(point => ({...point, time: convertTimestamp(point.timestamp)}));
  data.coolingMachineValues1.reverse();
  data.coolingMachineValues2.reverse();
  data.heatingMachineValues = pointMap['DHW Building Supply Temp Series'].map(point => ({...point, time: convertTimestamp(point.timestamp)}));;
  data.heatingMachineValues.reverse();
  data.heatingValue = Math.round(parseFloat(pointMap['DHW Building Supply Temp'].value));
  data.coolingValue = [Math.round(parseFloat(pointMap['North Htg Supply Temp'].value)), Math.round(parseFloat(pointMap['South Htg Supply Temp'].value))];
  data.coolingMachineStatuses = [
    {label: 'Heating Boiler 1', status: pointMap['Boiler 1 Status'].value === 'On'},
    {label: 'Heating Boiler 2', status: pointMap['Boiler 2 Status'].value === 'On'},
    {label: 'Heating Boiler 3', status: pointMap['Boiler 3 Status'].value === 'On'},
  ];
  data.heatingMachineStatuses = [
    {label: 'DHW Boiler 1', status: pointMap['DHW Boiler 1 Status'].value === 'On'},
    {label: 'DHW Boiler 2', status: pointMap['DHW Boiler 2 Status'].value === 'On'},
  ]

  data.isWinter = pointMap['Summer/Winter Switch'].value == 'Heat';

  data.lastUpdated = moment();
  dispatch({type: buildingTypes.BUILDING_LOADING_SUCCESS, payload: data});
};