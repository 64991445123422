import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import {useSelector} from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  title: {
    width: '100%',
    textTransform: 'uppercase',
    textAlign: 'left'
  },
  left: {
    flex: 1,
    width: '50%',
    position: 'relative',
  },
  right: {
    flex: 1,
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    justifyContent: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: '2rem',
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  buildingCount: {
    position: 'absolute',
    display: 'inline',
    top: '25%',
    width: '80%',
    textAlign: 'center',
    left: '10%',
  },
}));

const CurrentActivity = (props) => {
  const classes = useStyles();
  const currentBuilding = useSelector(state => state.building.currentBuilding);
  const hasAlert = currentBuilding.alertCount > 0;
  const theme = useTheme();
  useEffect(() => {
    const ctx = document.getElementById('currentActivityCanvas').getContext('2d');
    ctx['$chartjs'] = undefined;
    const data = hasAlert ? [2, 1] : [3, 0]
    const currentActivityChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data,
            backgroundColor: [theme.palette.success.main, theme.palette.error.main],
            borderWidth: 1,
            borderRadius: 8,
            cutout: '90%',
          },
        ],
      },
    });
    return () => currentActivityChart.destroy();
  }, [theme.palette.error.main, theme.palette.success.main]);
  return (
    <div className={classes.wrapper}>
      <Typography variant='h5' className={classes.title}>
        Current status
      </Typography>
      <div className={classes.left}>
        <Typography variant='h4' className={classes.buildingCount}>
          3 buildings
        </Typography>
        <canvas id='currentActivityCanvas' />
      </div>
      <div className={classes.right}>
        <div className={classes.item}>
          <CheckCircleRoundedIcon className={`${classes.icon} ${classes.success}`} />
          <Typography variant='subtitle1'>{hasAlert ? 2 : 3} OK</Typography>
        </div>
        <div className={classes.item}>
          <CancelRoundedIcon className={`${classes.icon} ${classes.error}`} />
          <Typography variant='subtitle1'>{hasAlert ? 1 : 0} alert</Typography>
        </div>
      </div>
    </div>
  );
};

export default CurrentActivity;
