import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Chart from 'chart.js/auto';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: '16px !important',
    fontWeight: 'bold !important',
    textTransform: 'uppercase !important',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right'
  }
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const drawGas = (gasPoints, oldGasPoints, id) => {
  const gasCtx = document.getElementById(id).getContext('2d');
  const gasChart = new Chart(gasCtx, {
    type: 'bar',
    data: {
      labels: gasPoints.map(point => point[0]),
      datasets: [
        {
          label: 'Prior year',
          data: oldGasPoints.map(point => point[1]),
          backgroundColor: '#d66264',
        },
        {
          label: 'Current year',
          data: gasPoints.map(point => point[1]),
          backgroundColor: '#304659',
        },
      ],
    },
  });
  return gasChart;
};

const drawWater = (waterPoints, oldWaterPoints, id) => {
  const waterCtx = document.getElementById(id).getContext('2d');
  const waterChart = new Chart(waterCtx, {
    type: 'bar',
    data: {
      labels: waterPoints.map(point => point[0]),
      datasets: [
        {
          label: 'Prior Year',
          data: oldWaterPoints.map(point => point[1]),
          backgroundColor: '#d66264',
        },
        {
          label: 'Current Year',
          data: waterPoints.map(point => point[1]),
          backgroundColor: '#304659',
        },
      ],
    },
  });
  return waterChart;
};

const drawElec = (elecPoints, oldElecPoints, id) => {
  const elecCtx = document.getElementById(id).getContext('2d');
  console.log(oldElecPoints);
  const elecChart = new Chart(elecCtx, {
    type: 'bar',
    data: {
      labels: elecPoints.map(point => point[0]),
      datasets: [
        {
          label: 'Prior Year',
          data: oldElecPoints.map(point => point[1]),
          backgroundColor: '#d66264',
        },
        {
          label: 'Current Year',
          data: elecPoints.map(point => point[1]),
          backgroundColor: '#304659',
        },
      ],
    },
  });
  return elecChart;
};

const GasWaterElecInfo = ({ gasValues, oldGasValues, waterValues, oldWaterValues, elecValues, oldElecValues }) => {
  const classes = useStyles();
  const [gasCanvasId, setGasCanvasId] = useState('gas-canvas');
  const [waterCanvasId, setWaterCanvasId] = useState('water-canvas');
  const [elecCanvasId, setElecCanvasId] = useState('elec-canvas');
  const [currentTab, setCurrentTab] = useState(0);
  useEffect(() => {
    let chart;
    if (currentTab === 0) {
      try {
        chart = drawWater(waterValues, oldWaterValues, waterCanvasId);
      } catch (e) {
        setWaterCanvasId(`water-canvas-${Math.round(Math.random() * 10000)}`)
      }
    } else if (currentTab === 1) {
      try {
        chart = drawGas(gasValues, oldGasValues, gasCanvasId);
      } catch (e) {
        setGasCanvasId(`gas-canvas-${Math.round(Math.random() * 10000)}`)
      }
    } else {
      try {
        chart = drawElec(elecValues, oldElecValues, elecCanvasId);
      } catch (e) {
        setElecCanvasId(`elec-canvas-${Math.round(Math.random() * 10000)}`)
      }
    }
    return () => chart && chart.destroy();
  }, [oldGasValues, oldWaterValues, oldElecValues, currentTab, gasValues, elecValues, waterValues, waterCanvasId, gasCanvasId, elecCanvasId]);
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h6' className={classes.subtitle}>
        Current Year Utility Statistics
        <div className={classes.buttonContainer}>
          <Button variant='contained' style={{marginBottom: '8px', minHeight: '40px'}}>Live consumption data</Button>
          <Button variant='contained'>Export</Button>
        </div>
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)} variant='fullWidth'>
          <Tab label='WATER' />
          <Tab label='GAS' />
          <Tab label='ELECTRICITY' />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <canvas id={waterCanvasId} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <canvas id={gasCanvasId} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <canvas id={elecCanvasId} />
      </TabPanel>
    </Box>
  );
};

export default GasWaterElecInfo;
