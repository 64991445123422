import React from 'react';
import HeatingCoolingInfo from './HeatingCoolingInfo';
import GasWaterElecInfo from './GasWaterElecInfo';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

const Statistics = ({ heatingMachineStatuses, coolingMachineStatuses, heatingValue, coolingValue, elecValues, oldElecValues, gasValues, waterValues, oldWaterValues, oldGasValues, heatingMachineValues, coolingMachineValues1, coolingMachineValues2 }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <GasWaterElecInfo oldElecValues={oldElecValues} oldWaterValues={oldWaterValues} elecValues={elecValues} gasValues={gasValues} oldGasValues={oldGasValues} waterValues={waterValues} />
      <HeatingCoolingInfo heatingValue={heatingValue} coolingValue={coolingValue} heatingMachineStatuses={heatingMachineStatuses} coolingMachineStatuses={coolingMachineStatuses} heatingMachineValues={heatingMachineValues} coolingMachineValues1={coolingMachineValues1} coolingMachineValues2={coolingMachineValues2} />
    </div>
  );
};

export default Statistics;
